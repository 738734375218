import { Injectable } from '@angular/core';
import { Cookie } from '@model/cookie';
import { LanguageCode } from '@model/language';
import { Setting } from '@model/setting.model';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Constants } from '../constants';
import { visplannerDatabase } from '../shared/svnl-database';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private table = visplannerDatabase.setting;

  private getSetting(): Observable<Setting> {
    return from(this.table.get(Constants.SETTINGS_ID)).pipe(
      map(setting => (setting ? setting : { id: Constants.SETTINGS_ID })),
    );
  }

  private saveSetting(setting: Setting): Observable<boolean> {
    return from(this.table.put(setting)).pipe(map(() => true));
  }

  /** Last Updated **/

  getLastUpdated(): Observable<string | undefined> {
    return this.getSetting().pipe(map(setting => setting?.lastUpdated));
  }

  setLastUpdated(lastUpdated: string): Observable<boolean> {
    return this.getSetting().pipe(
      map(setting => ({ ...setting, lastUpdated })),
      mergeMap(setting => this.saveSetting(setting)),
    );
  }

  /** MapState **/

  //todo: todo: depending on test from SVNL regearding map state, remove this

  // getMapState(): Observable<MapState | undefined> {
  //   return this.getSetting().pipe(map(setting => setting?.mapState));
  // }

  // setMapState(mapState: MapState): Observable<boolean> {
  //   return this.getSetting().pipe(
  //     map(setting => ({ ...setting, mapState })),
  //     mergeMap(setting => this.saveSetting(setting)),
  //   );
  // }

  // clearMapstate(): Observable<boolean> {
  //   return this.getSetting().pipe(
  //     map(setting => ({ ...setting, mapState: undefined })),
  //     mergeMap(setting => this.saveSetting(setting)),
  //   );
  // }

  /** LanguageCode **/

  getLanguageCode(): Observable<LanguageCode | undefined> {
    return this.getSetting().pipe(map(setting => setting?.languageCode));
  }

  setLanguageCode(languageCode: LanguageCode): Observable<boolean> {
    return this.getSetting().pipe(
      map(setting => ({ ...setting, languageCode })),
      mergeMap(setting => this.saveSetting(setting)),
    );
  }

  /** Cookies **/

  getCookies(): Observable<Cookie[] | undefined> {
    return this.getSetting().pipe(map(setting => setting?.cookies));
  }

  setCookies(cookies: Cookie[]): Observable<boolean> {
    return this.getSetting().pipe(
      map(setting => ({ ...setting, cookies })),
      mergeMap(setting => this.saveSetting(setting)),
    );
  }
}
