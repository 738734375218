import { inject, Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { LanguageService } from '@service/language.service';
import { mergeMap, Observable } from 'rxjs';

import { WatersService } from './waters.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializeService {
  private watersService = inject(WatersService);
  private languageService = inject(LanguageService);

  initialize(): Observable<boolean> {
    App.addListener('resume', () =>
      this.watersService.checkWaters().subscribe(),
    );

    return this.watersService
      .initialize()
      .pipe(mergeMap(() => this.languageService.init()));
  }
}
