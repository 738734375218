<ion-header>
  <ion-toolbar color="primary">
    <ion-title> {{ 'cookies.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-text color="primary">
          {{ 'cookies.description' | translate }}
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          @for (
            selectableCookie of selectableCookies();
            track selectableCookie.cookie
          ) {
            <ion-item
              lines="none"
              (click)="toggleCookie(selectableCookie)"
              [disabled]="selectableCookie.disabled"
            >
              <ion-checkbox slot="start" [checked]="selectableCookie.selected">
              </ion-checkbox>
              <ion-text slot="end" class="ion-padding">
                {{ selectableCookie.description | translate }}
              </ion-text>
            </ion-item>
          }
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button expand="block" color="primary" (click)="apply()">
      {{ 'button.apply' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
