import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { KeycloakService } from '../keycloak/service/keycloak.service';

export const skipStartGuard: CanActivateFn = async () => {
  const keycloakService = inject(KeycloakService);
  const router = inject(Router);

  return keycloakService.isAuthenticated().then(authenticated => {
    if (authenticated) {
      router.navigate(['tabs', 'map']);

      return false;
    }

    return true;
  });
};
