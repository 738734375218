import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsService } from '@service/settings.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { from, Observable, Subscription, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private pageSubscription$?: Subscription;

  private googleTagManagerService = inject(GoogleTagManagerService);
  private router = inject(Router);
  private settingsService = inject(SettingsService);

  // check what the user has set in the cookie settings and initialize usage of the GTM accordingly
  initialize(): Observable<void> {
    return this.settingsService.getCookies().pipe(
      map(cookies => {
        if (cookies && cookies.includes('analytics')) {
          if (environment.analytics?.google?.gtm) {
            from(this.googleTagManagerService.addGtmToDom()).pipe(
              tap(() => {
                this.trackPages();
              }),
            );
          }
        } else {
          this.pageSubscription$?.unsubscribe();
        }
      }),
    );
  }

  trackPages(): void {
    this.pageSubscription$ = this.router.events.subscribe(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.googleTagManagerService.pushTag(gtmTag);
      }
    });
  }
}
