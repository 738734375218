import { Injectable, signal } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  connectionStatus = signal<ConnectionStatus | undefined>(undefined);

  async init(): Promise<void> {
    const status = await Network.getStatus();

    if (status) {
      console.log('Connection status', status);
      this.connectionStatus.set(status);
    }

    Network.addListener('networkStatusChange', connectionStatus => {
      console.log('Nieuwe networkstatus', connectionStatus);
      this.connectionStatus.set(connectionStatus);
    });
  }

  isConnected(): boolean {
    return this.connectionStatus()?.connected ?? false;
  }
}
