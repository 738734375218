import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ErrorService } from '@service/error.service';
import { NetworkService } from '@service/network.service';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Log, User, UserManager } from 'oidc-client-ts';

import { environment } from '../../../environments/environment';
import { KeycloakStateStore } from '../storage/keycloak.storage';

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  private user?: User;
  private userManager: UserManager;

  private networkService = inject(NetworkService);
  private router = inject(Router);
  private kcStateStore = inject(KeycloakStateStore);
  private errorService = inject(ErrorService);

  constructor() {
    if (!environment.production) {
      Log.setLogger(console);
      Log.setLevel(Log.DEBUG);
    }

    const settings = environment.keycloakConf.settings;
    settings.userStore = this.kcStateStore;

    if (Capacitor.getPlatform() === 'web')
      settings.redirect_uri = `${document.location.origin}/tabs/map`;
    else if (Capacitor.getPlatform() === 'ios')
      settings.redirect_uri = environment.keycloakConf.ios.redirectUri;
    else if (Capacitor.getPlatform() === 'android')
      settings.redirect_uri = environment.keycloakConf.android.redirectUri;

    if (Capacitor.getPlatform() === 'web')
      settings.post_logout_redirect_uri = `${document.location.origin}`;
    else if (Capacitor.getPlatform() === 'ios')
      settings.post_logout_redirect_uri =
        environment.keycloakConf.ios.postLogoutRedirectUri;
    else if (Capacitor.getPlatform() === 'android')
      settings.post_logout_redirect_uri =
        environment.keycloakConf.android.postLogoutRedirectUri;

    settings.revokeTokensOnSignout = true;

    this.userManager = new UserManager(settings);
    this.userManager.getUser().then(user => (this.user = user ?? undefined));
  }

  async getToken(): Promise<string> {
    if (this.user && (await this.isAuthenticated())) {
      return this.user.access_token;
    } else {
      console.log('get token ended', Date.now());

      return '';
    }
  }

  async handleLoginCallback(url: string): Promise<void> {
    try {
      const signedInUser = await this.userManager.signinRedirectCallback(url);
      console.log(
        `Got user after callback login : ${JSON.stringify(signedInUser)}`,
      );
      this.user = signedInUser;
    } catch (error) {
      this.errorService.captureException(error);
    }
  }

  async handleLoginWebRedirect(): Promise<void> {
    console.log('webredirect triggered');
    try {
      const signedInUser = await this.userManager.signinCallback();
      if (signedInUser instanceof User) {
        console.log(
          `Got user after web redirect login : ${JSON.stringify(signedInUser)}`,
        );
        this.user = signedInUser;
      }
    } catch (error) {
      console.log(`Got error when handle web redirect login: ${error}`);
    }
  }

  returnUser(): boolean {
    return this.user !== null;
  }

  async handleSignInSilent(): Promise<boolean> {
    try {
      const signedInUser = await this.userManager.signinSilent();
      if (signedInUser instanceof User) {
        console.log(
          `Got user after silent signIn : ${JSON.stringify(signedInUser)}`,
        );
        this.user = signedInUser;

        return true;
      }

      return false;
    } catch (e) {
      console.log(`Got error when trying to refresh token: ${e}`);
      this.errorService.captureException(e);

      return false;
    }
  }

  async init(): Promise<boolean> {
    this.addAppUrlOpenListener();

    console.log('Keycloak service initializing..');
    const status = this.networkService.isConnected();
    console.log(`Got network / connection status : ${status}`);
    if (status === true) {
      console.debug('Got active network, checking for stored user');

      // Web login is for development purposes only, so not too sturdy
      const platform = Capacitor.getPlatform();
      if (platform === 'web' && window.location.href.includes('state')) {
        await this.handleLoginWebRedirect();
      }

      const isAuthenticated = await this.isAuthenticated();

      if (!isAuthenticated) {
        console.log('No active user found, proceed with login flow');
      }

      return !!this.user;
    } else {
      console.log('No active internet connection, skipping login');
      this.user = (await this.userManager.getUser()) ?? undefined;

      return true;
    }
  }

  async isAuthenticated(): Promise<boolean> {
    if (!this.user) {
      console.log('No user object found, not authenticated');

      return false;
    }
    if (!this.networkService.isConnected()) {
      console.log('User not connected, treat user as authenticated');

      return true;
    }
    console.log(`Is user expired? ${this.user.expired}`);
    if (this.user.expired) {
      const res = await this.handleSignInSilent();

      return res;
    }

    return true;
  }

  isTokenExpired(token: string): boolean {
    const { exp } = jwtDecode<JwtPayload>(token);

    if (exp) {
      const currentTime = new Date().getTime() / 1000;

      return currentTime > exp;
    }

    return true;
  }

  logout(): void {
    // Need token as id_hint to skip logout screen
    const idToken = this.user?.id_token;
    this.clearUser()
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .then(() => this.userManager.signoutRedirect({ id_token_hint: idToken }));
  }

  async refreshOrLogin(): Promise<User | void | null> {
    console.log('Trying to get user from storage...', Date.now());

    if (!this.user) {
      this.user = (await this.userManager.getUser()) ?? undefined;
    }
    if (this.user) {
      if (this.user?.expired) {
        if (this.isTokenExpired(this.user.refresh_token as string)) {
          console.log('refresh token expired, redirecting to login');
          await this.signinRedirect();
        } else {
          console.log(
            `Found stored user ${JSON.stringify(this.user)}, but token is expired, performing refresh`,
          );
          const success = await this.handleSignInSilent();
          if (!success) {
            this.userManager
              .removeUser()
              .then(() => this.userManager.clearStaleState())
              .then(() => (this.user = undefined));
            await this.signinRedirect();
          }
        }
      }
      console.log(' refreshOrLogin ended ', Date.now());

      return this.user;
    }
    console.log('No user found, redirecting to login page.');

    return await this.signinRedirect();
  }

  private async signinRedirect(): Promise<void> {
    return this.userManager.signinRedirect({ scope: 'offline_access' });
  }

  async clearUser(): Promise<void> {
    this.userManager
      .removeUser()
      .then(() => this.userManager.clearStaleState())
      .then(() => (this.user = undefined));
  }

  async updateToken(): Promise<boolean> {
    const authenticated = await this.isAuthenticated();

    if (!authenticated) {
      return this.handleSignInSilent().then(() => true);
    }

    return false;
  }

  private addAppUrlOpenListener(): void {
    App.addListener('appUrlOpen', data => {
      if (data.url.includes('callback')) {
        this.handleLoginCallback(data.url).then(() => {
          console.log('Done handling OAUTH callback, proceeding.');
          this.router.navigate(['tabs', 'map']);
        });
      } else {
        this.router.navigate(['/start']);
      }
    });
  }
}
