import { Environment } from './environment.model';

export const environment: Environment = {
  name: 'visplanner',
  version: '3.3.0',
  environment: 'test',
  production: false,
  serverUrl: 'https://test.visplanner.nl/api',
  funnelUrl: 'https://test.vispas.nl',
  portalUrl: 'https://test.mijnsportvisserij.nl',
  connectPermitUrl: 'https://test.mijnsportvisserij.nl/coupling/cardnumber',
  debug: true,
  arcgis: {
    webMaps: [
      {
        id: 'topoRD',
        portalId: '681309c4db7c4849ba7f079a7d314192',
      },
      {
        id: 'satellite',
        portalId: 'eac7c0dc00c24421a14b424dffc10414',
      },
    ],
  },
  configure: {
    android: {
      applicationId: 'visplanner.snl.flusso.nl',
      appName: 'visplanner',
      iconName: '@mipmap/ic_launcher',
      roundIcon: '@mipmap/ic_launcher_round',
      splashDrawable: '@drawable/splash',
    },
    deeplinkUrl: '',
    ios: {
      bundleId: '',
    },
  },
  keycloakConf: {
    settings: {
      authority: 'https://test.mijnsportvisserij.nl/auth/realms/Svnl/',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: 'visplanner',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      post_logout_redirect_uri: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      response_type: 'code',
    },
    android: {
      redirectUri: 'svnl://visplanner/callback',
      postLogoutRedirectUri: 'svnl://visplanner/start',
    },
    ios: {
      redirectUri: 'https://test.visplanner.nl/callback',
      postLogoutRedirectUri: 'https://test.visplanner.nl/start',
    },
  },
  sentry: {
    dsn: 'https://602d07a164d84dcfae43f2120c856867@sentry.flusso.nl/29',
  },
  analytics: {
    google: {
      gtm: 'GTM-N8LL93S',
    },
  },
};
