import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, mergeMap, Observable } from 'rxjs';

import { KeycloakService } from '../keycloak/service/keycloak.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes('profile')) {
      return from(this.keycloakService.refreshOrLogin()).pipe(
        mergeMap(() => this.keycloakService.getToken()),
        mergeMap(token => {
          request = request.clone({
            setHeaders: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          });

          return next.handle(request);
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
