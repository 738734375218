import { Component, inject, Input, OnInit, signal } from '@angular/core';
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonItem,
  IonList,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  ModalController,
} from '@ionic/angular/standalone';
import { Cookie, cookieList } from '@model/cookie';
import { TranslateModule } from '@ngx-translate/core';

type SelectableCookie = {
  cookie: Cookie;
  selected: boolean;
  disabled: boolean;
  description: string;
};

@Component({
  selector: 'svvp-cookie-component',
  templateUrl: './cookie.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonCheckbox,
    IonText,
    IonFooter,
    IonGrid,
    IonCol,
    IonRow,
  ],
})
export class CookieComponent implements OnInit {
  @Input() selectedCookies: Cookie[] = [];

  selectableCookies = signal<SelectableCookie[]>([]);

  private modalController = inject(ModalController);

  ngOnInit(): void {
    this.selectableCookies.set(
      cookieList.map(cookie => ({
        cookie,
        selected: this.selectedCookies?.includes(cookie),
        disabled: cookie === 'functional',
        description: `cookies.${cookie}.description`,
      })),
    );
  }

  toggleCookie(selectedCookie: SelectableCookie): void {
    this.selectableCookies.update(selectableCookies =>
      selectableCookies.map(selectableCookie =>
        selectableCookie.cookie === selectedCookie.cookie
          ? { ...selectableCookie, selected: !selectableCookie.selected }
          : selectableCookie,
      ),
    );
  }

  apply(): void {
    const cookies = this.selectableCookies()
      .filter(selectableCookie => selectableCookie.selected)
      .map(selectableCookie => selectableCookie.cookie);
    this.modalController.dismiss(cookies);
  }
}
