import { inject, Injectable } from '@angular/core';
import { IonRouterOutlet } from '@ionic/angular';
import { ModalController } from '@ionic/angular/standalone';
import { Cookie, cookieList } from '@model/cookie';
import { AnalyticsService } from '@service/analytics.service';
import { SettingsService } from '@service/settings.service';
import { mergeMap } from 'rxjs';

import { CookieComponent } from '../modals/cookie/cookie.component';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private analyticsService = inject(AnalyticsService);
  private modalController = inject(ModalController);
  private settingsService = inject(SettingsService);

  // save the new cookies to storage and use the analytics service to apply them
  applyAndSaveCookies(cookies: Cookie[]): void {
    this.settingsService
      .setCookies(cookies)
      .pipe(mergeMap(() => this.analyticsService.initialize()))
      .subscribe();
  }

  // open the cookie modal, if the user has preferences set use these, otherwise use the default settings
  async openCookieModal(
    routerOutlet: IonRouterOutlet,
    selectedCookies?: Cookie[],
  ): Promise<void> {
    // If selectedCookies is undefined, provide all cookies as selected
    const cookieModal: HTMLIonModalElement = await this.modalController.create({
      component: CookieComponent,
      backdropDismiss: false,
      componentProps: {
        selectedCookies: selectedCookies ?? Array.from(cookieList),
      },

      presentingElement: routerOutlet.nativeEl,
    });

    await cookieModal.present();

    const { data: updatedCookies } = await cookieModal.onDidDismiss<Cookie[]>();

    if (updatedCookies) {
      this.applyAndSaveCookies(updatedCookies);
    }
  }
}
