import { Setting } from '@model/setting.model';
import { Water } from '@svnl/shared';
import Dexie, { Table } from 'dexie';

import { Constants } from '../constants';

export class SvnlDatabase extends Dexie {
  water!: Table<Water, number>;
  setting!: Table<Setting, number>;

  constructor() {
    super(Constants.DATABASE_NAME);
    this.version(1).stores({
      water: 'id, name',
      setting: 'id',
    });
    // Version 2 of the database (app version 3.1.0)
    // Structure is the same
    // Setting table will be cleared to process updated mapType field
    this.version(2)
      .stores({
        water: 'id, name',
        setting: 'id',
      })
      .upgrade(trans => trans.table('setting').delete(Constants.SETTINGS_ID));
  }
}

export const visplannerDatabase = new SvnlDatabase();
