import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import * as SentryAngular from '@sentry/angular';
import { Breadcrumb, TransactionEvent } from '@sentry/types';
import { NetworkService } from '@service/network.service';
import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  networkService = inject(NetworkService);

  initialize(): Observable<void> {
    console.debug('init sentry', environment);

    SentryAngular.init({
      dsn: environment.sentry.dsn,
      release: `${environment.name}@${environment.version}`,
      environment: `${environment.environment}`,
      debug: !environment.production,
      beforeBreadcrumb: (breadcrumb: Breadcrumb) => {
        if (breadcrumb.type === 'http') {
          if (breadcrumb.data?.url && breadcrumb.data?.url.startsWith('/svg')) {
            // Don't clutter the breadcrumbs with the retrieval of svgs
            return null;
          }
        }

        return breadcrumb;
      },
      beforeSendTransaction: (transactionEvent: TransactionEvent) => {
        if (this.networkService.isConnected()) {
          return transactionEvent;
        }

        return null;
      },
    });

    return of();
  }

  handleError(errorMessage: string, error: HttpErrorResponse): void {
    console.error(errorMessage, error);
    SentryAngular.captureException(error);
  }

  captureException(error: unknown): void {
    console.error(error);
    SentryAngular.captureException(error);
  }
}
