import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { RequiredDocumentLabelType } from '@model/required-document-label-type';
import { WatersResponse } from '@model/waters.model';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@service/settings.service';
import { ToastService } from '@service/toast.service';
import { RequiredDocument } from '@svnl/shared';
import { Water } from '@svnl/shared';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { visplannerDatabase } from '../shared/svnl-database';
import { filterWaters } from '../waters/waters-util';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class WatersService extends BaseService {
  private table = visplannerDatabase.water;

  private translateService = inject(TranslateService);

  constructor(
    protected httpClient: HttpClient,
    private settingsService: SettingsService,
    private toastController: ToastController,
    private toastService: ToastService,
  ) {
    super(httpClient, 'waters');
  }

  initialize(): Observable<boolean> {
    // Check the indexDB for the last updated date on waters
    // Process the loading of waters using the given date
    // Catch a possible error and show it on the console
    return this.settingsService.getLastUpdated().pipe(
      mergeMap(lastUpdated => this.loadWaters(lastUpdated)),
      catchError(error => {
        console.error(`Got error retrieving waters: ${JSON.stringify(error)}`);
        // TODO: translate toast!
        this.toastService.warning(
          'Er ging iets mis bij het ophalen van de viswateren',
        );
        throw error;
      }),
    );
  }

  async presentToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
    });

    await toast.present();
  }

  checkWaters(): Observable<boolean> {
    // Check if there is data in the waters table (count > 0)
    // Process load of waters if no data is found
    return from(this.table.count()).pipe(
      mergeMap(count => (count > 0 ? of(true) : this.loadWaters())),
    );
  }

  private loadWaters(lastUpdated?: string): Observable<boolean> {
    // Perform the remote load of waters by a given last updated date
    // If the result is a response with a filled "lastUpdate" update the waters
    // If no result, the backend indicates there are no updates since the last check
    return this.getRemoteWaters(lastUpdated).pipe(
      mergeMap(watersResponse =>
        watersResponse?.lastUpdated
          ? this.updateWaters(watersResponse)
          : of(true),
      ),
    );
  }

  private updateWaters(watersRespone: WatersResponse): Observable<boolean> {
    // Perform an update of the new waters in the database
    // First save the given "lastUpdated" in the local storage
    // Cleanup the available waters in the database (delete not received records from the backend)
    // Put the received waters in bulk in the database
    return this.settingsService.setLastUpdated(watersRespone.lastUpdated).pipe(
      mergeMap(() => this.cleanupWaters(watersRespone.waters)),
      mergeMap(() => from(this.table.bulkPut(watersRespone.waters))),
      mergeMap(() => of(true)),
    );
  }

  private cleanupWaters(waters: Water[]): Observable<void> {
    // Check the stored waters in the table against the new list, bulkDelete the found deleted ids
    return from(this.table.toArray()).pipe(
      map(storedWaters =>
        storedWaters
          .filter(
            storedWater =>
              waters.find(water => water.id === storedWater.id) === undefined,
          )
          .map(deletedItem => deletedItem.id),
      ),
      switchMap(deletedIds => this.table.bulkDelete(deletedIds)),
    );
  }

  getWaters(): Observable<Water[]> {
    return from(this.table.orderBy('name').toArray());
  }

  searchWaters(query: string): Observable<Water[]> {
    if (query.trim() === '') {
      return of([]);
    }

    return this.getWaters().pipe(map(waters => filterWaters(waters, query)));
  }

  findById(id: number): Observable<Water | undefined> {
    return from(this.table.get(id));
  }

  private getRemoteWaters(lastUpdated?: string): Observable<WatersResponse> {
    let params: HttpParams = new HttpParams();
    if (lastUpdated) {
      params = params.append('lastUpdated', lastUpdated);
    }

    return this.httpClient
      .get<WatersResponse>(`${this.endpointUrl}/update`, { params })
      .pipe(
        catchError(error => {
          console.error(
            `Error retrieving waters from remote URL : ${JSON.stringify(error)}`,
          );
          throw error;
        }),
      );
  }

  requiredDocumentLabels(
    water: Water,
    type: RequiredDocumentLabelType = 'default',
  ): string[] {
    let requiredDocuments: RequiredDocument[] = water.requiredDocuments;

    if (type === 'short') {
      requiredDocuments = requiredDocuments.filter(
        requiredDocument => requiredDocument.showInPopup === true,
      );
    }

    return water.isOffshore
      ? []
      : requiredDocuments.map(requiredDocument =>
          this.translatedRequiredDocument(requiredDocument, type),
        );
  }

  private translatedRequiredDocument(
    requiredDocument: RequiredDocument,
    type: RequiredDocumentLabelType,
  ): string {
    let label: 'kvp' | 'svnl' | 'fed' | 'hsv';

    switch (requiredDocument.organisation.type) {
      case 'SVN':
        label = requiredDocument.permitType === 'KVP' ? 'kvp' : 'svnl';
        break;
      case 'FED':
        label = 'fed';
        break;
      default:
        label = 'hsv';
        break;
    }

    const translateOptions =
      label === 'fed' || label === 'hsv'
        ? {
            name: requiredDocument.organisation.name,
            city: requiredDocument.organisation.city,
          }
        : undefined;

    return this.translateService.instant(
      `water.requiredDocument.${type}.${label}`,
      translateOptions,
    );
  }
}
